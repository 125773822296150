/** Typography **/
$font-roboto: 'Roboto', sans-serif;
$font-primary: $font-roboto;


/** Colours **/
$colour-black: #000;
$colour-darkblue: #151524;
$colour-bluegrey: #363f4d;
$colour-grey: #ebebeb;
$colour-darkred: #a90000;
$colour-white: #fff;

$colour-active-blue: #1d2c51;
$colour-active-grey: #fafafa;
$colour-active-red: #eb3e32;

$colour-button-hover: #1e2d50;