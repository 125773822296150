@import 'inc/_variables';
@import 'inc/slick';
@import 'inc/helpers';

* {
	font-family: $font-primary;
}

/** Buttons **/
button {
	&.primary {
		padding: 15px 40px;
		&.action {
			padding: 15px 40px;
			background: $colour-darkblue;
			color: $colour-white;
			&:hover {
				background-color: $colour-active-blue;
			}
			&.subscribe {
				padding: 7px 15px;
			}
		}
	}
}

/** Filter and Pagination **/
.pages {
	.action {
		box-shadow: none;
		border-radius: 0;
		color: $colour-white;
		background: $colour-darkblue;
		&.next:before {
			color: $colour-white;
		}
		&:hover {
			background-color: $colour-active-blue;
			&.next:before {
				color: $colour-white;
			}
		}
	}
	.pages-items {
		padding: 0 10px;
		.item {
			a,
			strong.page {
				color: $colour-darkblue;
				padding: 0 10px;
			}
			&.current,
			&:hover {
				a,
				strong {
					background-color: $colour-active-blue;
					color: $colour-white;
				}
			}
		}
	}
}
.field {
	.toolbar {
		select {
			background-color: transparent;
			box-shadow: none;
			border-radius: 0;
		}
	}
}

.modes {
	.modes-mode {
		background-color: transparent;
	}
}

.products {
	&.products-grid {
		.product-item-info {
			&:hover,
			&.active {
				box-shadow: 1px 0 2px 0 rgba(0, 0, 0, 0.3);
				margin: 0;
				.product-item-inner {
					box-shadow: none;
					margin-top: 10px;
				}
			}
		}
	}
	.product {
		text-align: center;
		.product-item-inner {
			display: block;
			position: relative!important;
			box-shadow: none;
			border: 0 none;
			width: auto!important;
			height: auto!important;
			margin: 0;
			padding: 0;
			.product-item-actions {
				.actions-primary,
				.actions-secondary {
					display: block;
					text-align: center;
					margin: 10px auto;
				}
			}
		}
	}
}

.page-products {
	.products-grid {
		.product.product-item {
			@media (min-width: 1024px), print {
				margin-left: calc((100% - 4 * 25%) / 3);
				padding: 0;
				width: 25%;
				padding: 0 20px;
			}
			.product-item-info {
				padding: 0 20px 20px;
				box-shadow: 1px 0 2px 0 rgba(0, 0, 0, 0);
				border: 1px solid transparent;
				&:hover, &:active {
					box-shadow: none;
					border: 1px solid #bbbbbb;
				}
				&:not(:hover):not(.active) .product-item-inner {
					margin: 0;
					height: 0;
				}
				.swatch-option {
					display: inline-block;
					text-align: center;
					float: none;
				}
			}
		}
	}
}

@media (min-width: 768px), print {
	.page-wrapper {
		.breadcrumbs {
			padding: 10px 0 10px 20px;
		}
	}
}

header.page-header {
	.panel.wrapper {
		background-color: $colour-darkred;
	}
	.logo {
		max-width: 204px;
	}
}
.nav-sections {
	@media (min-width: 768px), print {
		.navigation {
			.level0.active,
			.level0.has-active {
				> .level-top {
					border-color: $colour-darkred;
				}
			}
		}
	}
}

@media (min-width: 768px), print {
	body .page-footer {
		padding: 40px 20px;
		background: $colour-bluegrey;
		.footer.content {
			.links {
				li {
					margin-bottom: 14px;
					a,
					a:visited {
						color: $colour-white;
					}
				}
			}
		}
	}
	.footer-payment-methods {
		background: $colour-darkblue;
		.content {
			padding: 20px;
			.payment-methods {
				li {
					display: inline-block;
					margin-left: 5px;
					&:first-child {
						margin-left: 0;
					}
					i {
						display: block;
						cursor: default;
						padding: 4px;
						font-size: 26px;
						line-height: 1;
						background: 0 0;
						border: 1px solid $colour-white;
						border-radius: 3px;
						color: $colour-white;
						transition: all .5s;
						&:hover {
							background-color: $colour-white;
							color: $colour-active-blue;
						}
					}
				}
			}
			.copyright {
				display: inline-block;
				margin: 10px 0 0;
				background-color: transparent;
				padding: 0;
			}
		}
	}
}

// Mini-cart styling

div.product-item-details {
	div.product.options {
		dl.product.options {
			dt.label, dd.values {
			    width: 49%;
			    display: inline-block;
			    margin-bottom: 0;
			    @media (max-width: 768px) {
			    	width: 35%;
			    }
			}
			dt.label {
				font-weight: 700;
			}
			dd.values {
				font-weight: 300;
			}
		}
	}
}

// Checkout page styling tweaks

div#checkout {
	ul.opc-progress-bar {
		li.opc-progress-bar-item.final-completion {
			@media (min-width: 768px) {
				&:before {
				    width: 50%;
				}
				> span:after {
					border-color: #ff5501;
					content: '\e610';
					font-family: 'luma-icons';
				}
				> span:before {
					background: #ff5400;
					border: 1px solid #ffa77c;
				}
			}
		}
	}
}

// Shipping page button alterations

body.page-layout-checkout {
	ol#checkoutSteps {
		li#shipping {
			div.step-title {
				border-bottom: 0;
				span,
				div.primary {
					display: inline-block;
				}
				div.primary {
					float: right;
					button {
						margin: -10px 0 0 0px;
						@media (max-width: 767px) {
							margin: -5px 0 0 0px;
						}
					}
					#co-shipping-method-form {
						@media (max-width: 639px) {
							border: 0;
							background: transparent;
							padding: 0;
							margin: 0;
						}
					}
				}
				hr {
					@media (max-width: 639px) {
						margin-top: 35px;
					}
				}
			}
		}
	}
	.opc-block-summary .minicart-items-wrapper {
		max-height: 100%;
	}
}

// Cart page alterations

body.checkout-cart-index {
	@media (min-width: 768px) {
		div.cart-container {
			display: flex;
			flex-wrap: wrap;
			div.cart-summary {
				top: 0;
			}
			.cart-discount {
				order: 2;
			}
			.block.crosssell {
				margin-top: 0;
				.products {
					margin-top: 0;
				}
			}
		}
	}
}

// Checkout phase 'sign in' button errornously appearing

body.checkout-index-index .authentication-wrapper {
    display: none;
}
body.checkout-index-index hr {
    margin-top: 30px;
}
body.checkout-index-index li#shipping .primary {
    float: right;
    margin-top: 0;
}
body.checkout-index-index .opc-wrapper .step-title {
	border: 0;
}
@media (max-width: 639px) {
	.opc-wrapper .methods-shipping {
		border-bottom: 0;
		background: transparent;
		margin-top: -35px;
	}
}

// Fixing product view on certain resolutions

.column.main ol.product-items.widget-product-grid,
.column.main ol.product-items.widget-new-grid,
.column.main ol.product-items {
	display: flex;
	flex-wrap: wrap;
	margin: 0;
	position: relative;
	//justify-content: center;
	li.product-item {
		display: flex;
		padding: 0px;
		div.product-item-info {
			padding: 10px;
			margin: 0 auto;
			max-width: 240px;
			button.primary.action {
				padding: 10px 15px;
				background: #363f4c;
				color: #fff;
				font-weight: 300;
				width: auto;
				border: 0;
			}
		}
		@media (max-width: 1024px) {
			width: 49% !important;
			margin: 0 auto 10px;
		}
		@media (max-width: 580px) {
			width: 100% !important;
			margin: 0 auto 10px;
		}
	}
}

@media (min-width: 640px) {
	.products-grid .product-item-actions {
	    margin: 0;
	    white-space: nowrap;
	    .actions-primary {
	    	display: inline-block;
	    	width: 80%;
	    	overflow: hidden;
	    	text-align: left;
	    }
	    .actions-secondary {
	    	display: inline-block;
	    	padding-left: 10px;
	    	width: calc(20% - 12px);
	    	vertical-align: top;
	    }
	}
	.product-item-actions .actions-primary + .actions-secondary > .action {
	    display: block;
	}
	.block.widget .products-grid .product-item,
	.page-layout-1column .block.widget .products-grid .product-item,
	.page-layout-3columns .block.widget .products-grid .product-item {
	    width: 32%;
	}
	.products-grid .product-item-info {
	    padding: 0px;
	    margin: 0;
	    border: 1px solid transparent;
	}
	.products-grid .product-item-info:hover,
	.products-grid .product-item-info.active {
	    background: transparent;
	    box-shadow: none;
	    border: 1px solid #bbbbbb;
	    margin: 0px;
	    position: relative;
	    z-index: 2;
	}
}

@media (min-width: 768px) {
	.page-layout-1column .block.widget .products-grid .product-item {
	    width: 33% !important;
	}
}

@media (min-width: 1024px) {
	.page-layout-1column .block.widget .products-grid .product-item {
	    width: 20% !important;
	}
}

.products-grid .product-item {
    display: inline-block;
    width: 49%;
}

// Product listings style fixes

div.product-item-info {
	z-index: 3;
}

// .no-display still displaying products

.no-display {
	display: none !important;
}
button.action.toggle.change {
    display: none !important;
}

// Success page reordering of continue buttons

body.checkout-onepage-success {
	div.column.main {
		display: flex;
		flex-direction: column;
		.checkout-success {
		    order: 2;
		    margin-top: 20px;
		}
		div#registration {
			order: 1;
			br {
				display: none;
			}
		}
	}
}

// Fix overlapping elements on menu

nav.navigation {
	z-index: 5;
}

// Navigation styling

.menu .rightstatic {
    float: right;
    max-width: 400px;
}

#om ul {
	padding-left: 0;
}

#om ul ul.normal-sub {
    padding: 10px 20px;
}

.menu > ul > li {
	background: transparent;
}

.menu > ul > li a {
	color: #000;
	padding: 15px 30px;
}

.menu > ul > li > ul {
	background: #ffffff;
	border: 1px solid #ccc;
}

.menu > ul > li > ul > li {
	margin: 0 1%;
}

.menu > ul > li > ul > li a {
	padding: 0px 10px 10px;
}

.menu > ul > li > ul > li > ul > li {
	padding: 10px 0 0;
	transition: all 0.3s ease-in-out;
	&:hover {
	    background: #e6e6e6;
	}
}

// Homepage overflow fix

body.cms-home {
	.page-wrapper {
		overflow: hidden;
	}
	.columns .column.main {
		padding-bottom: 0;
	}
}

// Homepage quick shop styling

div#catewithimg {
    background: #ebebeb;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    padding: 30px 0;
    .block .title {
        margin-bottom: 40px;
    }
    &.cwiw div.row {
    	max-width: 1200px;
    	margin: 40px auto 30px;
    }
    .quick-shop-item {
        display: inline-block;
        position: relative;
        width: 100px;
        height: 130px;
        padding: 10px 20px;
        margin: 10px;
        background: #fff;
        outline: 3px solid #fff;
        transition: all 0.3s ease-in-out;
        &:hover {
        	background: #f3f3f3;
        }
        a {
        	position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 20px;
            h3 {
	            margin: 20px 0 0 0;
	            color: #000;
	            font-size: 16px;
	            font-weight: 700;
            }
        }
    }
}

// Homepage trending / new products

div.block.widget.block-products-list.grid,
div.block.widget.block-new-products.grid {
	div.block-title {
		text-align: center;
		position: relative;
		&:after {
			content: '';
			height: 1px;
			width: 100%;
			border-top: 1px solid #ccc;
			top: 13px;
			display: block;
			position: absolute;
		}
		strong {
			z-index: 1;
			background: #fff;
			padding-left: 15px;
			padding-right: 15px;
			position: relative;
		}
	}
}

// Homepage about section

.bg.bg-lightgrey {
    background: #ebebeb;
    padding: 40px 0;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    h2 {
    	margin-top: 0;
    }
    a {
    	color: #000;
    	text-decoration: underline;
    	&:hover {
    		font-weight: 700;
    	}
    }
    p {
    	width: 50%;
    	margin: 0 auto 20px;
    	max-width: 600px;
    	@media (max-width: 780px) {
    		width: 80%;
    	}
    }
}

// Homepage offer label

li.product-item,
li.product-item div.product-item-info {
	position: relative;
	overflow: hidden;
}

.price-box.price-final_price span.special-price:after {
    content: 'OFFER';
    width: 120px;
    height: 80px;
    text-align: center;
    display: block;
    background: #eb3e32;
    color: #fff;
    padding: 16px 0 16px 20px;
    top: -53px;
    left: -82px;
    position: absolute;
    transform: rotate(-45deg);
    line-height: 160px;
    font-weight: 700;
    z-index: 1;
}

// Homepage product listing style tweaks

.products-grid {
	.product-item-info {
		border: 2px solid #eeeeee;
		&:hover {
			border: 2px solid #eb3e32;
			div.product-item-details {
				color: #eb3e32;
				strong a,
				span.price-label,
				span.price-wrapper {
					color: #eb3e32;
				}
			}
		}
		div.product-item-details {
			text-align: center;
			div.product-reviews-summary {
				display: none;
			}
			.price-box {
			    margin: 0;
			    height: 45px;
			    span.special-price,
			    span.old-price {
			    	display: block;
			    	text-align: center;
			    	margin: 0;
			    }
			    span.price {
			    	font-weight: 300;
			    	font-size: 12px;
			    }
			}
			.product-item-name {
				text-align: center;
				margin: 0;
				a {
					font-weight: 700;
				}
			}
		}
		span.product-image-wrapper {
			padding-bottom: 90% !important;
		}
	}
	.product-item-actions {
		display: flex;
		flex-direction: column;
		div.actions-primary {
			order: 2;
			width: 100%;
			display: block;
			margin: 0 auto;
			text-align: center;
		}
		div.actions-secondary {
			order: 1;
			width: 100%;
			display: block;
			margin: 0 auto 10px;
			text-align: center;
			padding-left: 0;
			a {
				display: inline-block !important;
				margin: 0 !important;
				padding: 0 !important;
			}
		}
	}
}

// Home page slick carousel styling

div.widget.block.block-banners {
	position: relative;
	display: block;
	overflow: hidden;
}

div.widget.block.block-banners.logo-slider {
	margin: 0 auto;
	img {
		width: auto;
		margin: 0 auto;
	}
	div.slick-list {
		max-width: 90%;
		margin: 0 auto;
		position: relative;
		display: block;
	}
	button.slick-prev {
		font-size: 0;
		border: 0;
		box-shadow: none;
		color: #bfbfbf;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 3%;
		height: auto;
		line-height: 1;
		background: transparent;
		transition: all 0.3s ease-in-out;
		&:before {
			content: '<';
			font-size: 32px;
			font-weight: 700;
		}
		&:hover {
			background: transparent;
			color: #888888;
		}
		@media (max-width: 768px) {
			left: 0;
		}
	}
	button.slick-next {
		font-size: 0;
		border: 0;
		box-shadow: none;
		color: #bfbfbf;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 3%;
		height: auto;
		line-height: 1;
		background: transparent;
		transition: all 0.3s ease-in-out;
		&:before {
			content: '>';
			font-size: 32px;
			font-weight: 700;
		}
		&:hover {
			background: transparent;
			color: #888888;
		}
		@media (max-width: 768px) {
			right: 0;
		}
	}
}

// Hiding category image on the category view page

body.page-products {
	div.category-view {
		display: none;
	}
}

// Preventing extra drop spacing on hover product page

body.page-products {
	.products.products-grid .product.product-item .product-item-info:hover {
		border-color: #eb3e32;
		.product-item-inner {
			margin-top: 0;
		}
	}
}

// Slick slider carousel button positions

ul.slick-dots {
    position: absolute;
    bottom: 20px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    padding: 0;
    li {
    	display: inline-block;
    	margin: 0;
    	padding: 0;
    	button {
    		font-size: 0;
    		border-radius: 50%;
    		padding: 6px;
    		line-height: 1;
    		background: #fff;
    		margin: 0 3px;
    	}
    	&.slick-active {
    		button {
    			outline: 3px solid #777;
    			outline-offset: -9px;
    		}
    	}
    }
}

div.slick-slider {
	img {
		width: calc(100% - 9px);
		@media (max-width: 768px) {
			width: calc(100% - 4px);
		}
	}
	.widget.block.block-static-block {
		margin-bottom: 0;
	    position: absolute;
	    top: 50%;
	    left: 5%;
	    transform: translateY(-50%);
	    color: #fff;
	    p {
	    	max-width: 40%;
	    }
	    a {
	    	padding: 10px 15px;
	    	background: #fff;
	    	color: #000;
	    	font-weight: 300;
	    	width: auto;
	    	border: 0;
	    	font-size: 1.3rem;
	    	border-radius: 0;
	    	line-height: 1;
	    	cursor: pointer;
	    	margin-top: 10px;
	    	position: relative;
	    	display: inline-block;
	    	text-decoration: none;
	    	transition: all 0.3s ease-in-out;
	    	&:hover {
	    		text-decoration: none;
	    		color: #fff;
	    		background: #000;
	    		outline: 0;
	    	}
	    }
	}
}

body.cms-home {
	p.logo-slider-intro {
		text-align: center !important;
		background: #253346;
		padding: 40px 0 20px;
		color: #fff;
		margin-bottom: 0;
		width: 100vw;
		position: relative;
		left: 50%;
		right: 50%;
		margin-left: -50vw;
		margin-right: -50vw;
		@media (max-width: 768px) {
			padding: 20px;
			width: 100%;
		}
	}
	div.widget.block.block-banners.logo-slider {
		text-align: center !important;
		background: #253346;
		padding: 50px 0;
		color: #fff;
		margin-top: 0;
		width: 100vw;
		position: relative;
		left: 50%;
		right: 50%;
		margin-left: -50vw;
		margin-right: -50vw;
	}
	div.widget.block.block-banners.main-header {
		@media (max-width: 768px) {
			div.slick-slide {
				img {
					width: auto;
					height: auto;
					min-height: 300px;
				}
				.widget.block.block-static-block p {
					max-width: 65%;
				}
			}
		}
	}
}

// Homepage top 1/3 / 2/3 blocks

body.cms-home {
	.widget.block.block-static-block.small-block,
	.widget.block.block-static-block.large-block {
	    width: auto;
	    display: inline-block;
	    vertical-align: top;
	    max-height: 270px;
	    background-size: cover;
	    background-repeat: no-repeat;
	    height: 270px;
	    margin-top: -40px;
	    @media (max-width: 768px) {
	    	margin-top: -30px;
	    }
	    &.small-block {
	    	width: 33%;
	    	@media (max-width: 580px) {
	    		width: 100%;
	    	}
	    }
	    &.large-block {
	    	width: 65%;
	    	margin-left: 1%;
	    	p {
	    		max-width: 60%;
	    	}
	    	@media (max-width: 580px) {
	    		width: 100%;
	    		margin-top: -10px;
	    		margin-left: 0;
	    		p {
	    			max-width: 100%;
	    		}
	    	}
	    }
	    div.block-wrap {
	    	color: #fff;
	    	padding: 30px;
	    	@media (max-width: 768px) {
	    		padding: 0 20px;
	    	}
	    }
	    a {
	    	padding: 10px 15px;
	    	background: #fff;
	    	color: #000;
	    	font-weight: 300;
	    	width: auto;
	    	border: 0;
	    	font-size: 1.3rem;
	    	border-radius: 0;
	    	line-height: 1;
	    	cursor: pointer;
	    	margin-top: 10px;
	    	position: relative;
	    	display: inline-block;
	    	text-decoration: none;
	    	transition: all 0.3s ease-in-out;
	    	&:hover {
	    		text-decoration: none;
	    		color: #fff;
	    		background: #000;
	    		outline: 0;
	    	}
	    }
	}
}

// Cookie policy
.message.global.cookie {
	z-index: 100;
}

// Home page full-width image

p.full-width-image-block {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	img {
		width: 100%;
	}
}

// Header hiding on checkout

body.checkout-index-index {
	header.page-header {
		div.panel.wrapper {
			display: none;
		}
		div.header.content {
			div.block.block-search,
			div.minicart-wrapper {
				display: none;
			}
		}
	}
	footer.page-footer {
		display: none;
	}
}

// Header mobile navigation fix for confusing button to reopen quick-shop-item

@media only screen and (max-width: 767px) {
	.nav-open .nav-toggle:after {
	    background: rgba(0, 0, 0, 0.7);
	    content: '';
	    display: block;
	    height: calc(100% - 68px);
	    position: fixed;
	    right: 0;
	    top: auto;
	    bottom: 0;
	    width: 100%;
	    z-index: 1;
	}
}

@media only screen and (min-width: 640px) and (max-width: 767px) {
	.nav-open .nav-toggle:after {
	    height: calc(100% - 61px);
	}
}

// Fix for messed up products on list view

body.page-products {
	div.columns {
		div.column.main {
			div.products.products-list {
				ol.products.list {
					display: block;
					li.item.product.product-item {
						display: block;
						@media (max-width: 1024px) {
							width: 100% !important;
						}
						@media (max-width: 640px) {
							div.product-item-info {
								a,
								div.product.details.product-item-details,
								.product-image-container {
									display: block;
									width: 100% !important;
								}
							}
						}
						.product {
							text-align: left;
							.product-item-inner .product-item-actions .actions-primary,
							.product-item-inner .product-item-actions .actions-secondary {
								text-align: left;
							}
						}
					}
				}
			}
		}
	}
}


// Footer styling

html body footer.page-footer {
	padding: 20px 0;
	overflow: hidden;
	background: #363f4d;
	div.footer.wrap {
		margin-left: auto;
		margin-right: auto;
		max-width: 1280px;
		padding-left: 20px;
		padding-right: 20px;
		width: 100%;
		@media (max-width: 1024px) {
			width: 90%;
			margin: 0 auto;
			padding: 0;
		}
	}
	div.footer.content {
		display: inline-block;
		width: 64%;
		vertical-align: top;
		border: 0;
		@media (max-width: 1024px) {
			width: auto;
		}
		@media (max-width: 767px) {
			display: block;
			width: 100%;
			margin: 0 auto;
			padding: 0;
		}
		ul.footer.links {
			li.nav.item {
				font-size: 22px;
				line-height: 24px;
				display: inline-block;
				vertical-align: top;
				margin-right: 50px;
				background: transparent;
				border: 0;
				color: #fff;
				a {
					border-bottom: 3px solid #fff;
					display: inline-block;
					margin-bottom: 10px;
					color: #fff;
					&:hover {
						text-decoration: none;
					}
				}
				ul {
					li.nav.item {
						border: 0;
						font-size: 14px;
						display: block;
						line-height: 16px;
						margin-bottom: 0;
						a {
							border: 0;
							display: inline-block;
							margin: 0;
							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
				@media (max-width: 1024px) {
					margin-right: 10px;
				}
				@media (max-width: 767px) {
					width: 48%;
					margin: 0;
					padding: 0;
					text-align: center;
					ul {
						display: block;
						margin: 0 auto;
						li {
							margin: 0 auto;
						}
					}
				}
				@media (max-width: 560px) {
					width: 100%;
					margin: 0 auto 20px;
					li.nav.item {
						a {
							padding: 2px 0;
						}
					}
				}
			}
		}
		p a {
			margin-right: 20px;
			&:hover {
				img {
					transition: all 0.3s ease-in-out;
					opacity: 0.7;
				}
			}
		}
		p {
			@media (max-width: 767px) {
				text-align: center;
				margin: 0 5px;
				a {
					margin: 0 5px;
				}
			}
		}
	}
	div.footer.email {
		display: inline-block;
		width: 30%;
		vertical-align: top;
		color: #fff;
		height: 100%;
		.block.newsletter .field {
			min-width: 250px;
			@media (max-width: 1024px) {
				display: block;
				min-width: 220px;
			}
		}
		.block.newsletter .actions {
			@media (max-width: 1024px) {
				display: block;
				text-align: right;
				width: 100%;
			}
		}
		h2 {
			font-size: 22px;
			line-height: 24px;
			border-bottom: 3px solid #fff;
			display: inline-block;
			padding-bottom: 10px;
			margin-top: 30px;
		}
		p {
			margin-bottom: 20px;
		}
		@media (max-width: 767px) {
			display: block;
			width: 100%;
			text-align: center;
			.block.newsletter .form.subscribe {
			    display: block;
			    width: 100%;
			    max-width: 350px;
			    text-align: center;
			    margin: 0 auto;
			}
			.block.newsletter .actions {
				text-align: center;
			}
		}
	}
}

html body div.footer-payment-methods {
	background: $colour-darkblue;
	width: 100%;
	position: relative;
	display: block;
	overflow: hidden;
	div.payment-wrapper {
		margin-left: auto;
		margin-right: auto;
		max-width: 1280px;
		padding-left: 20px;
		padding-right: 20px;
		width: 100%;
		white-space: nowrap;
		position: relative;
		overflow: hidden;
		@media (max-width: 767px) {
			max-width: none;
			width: 100%;
			padding: 0;
			margin: 0;
			white-space: normal;
			overflow: hidden;
			background: $colour-darkblue;
			.content {
				padding: 20px;
				.payment-methods {
					margin: 0 auto 20px;
					display: block;
					text-align: center;
					li {
						display: inline-block;
						text-align: center;
						margin-left: 5px;
						&:first-child {
							margin-left: 0;
						}
						i {
							display: block;
							cursor: default;
							padding: 4px;
							font-size: 26px;
							line-height: 1;
							background: 0 0;
							border: 1px solid $colour-white;
							border-radius: 3px;
							color: $colour-white;
							transition: all .5s;
							&:hover {
								background-color: $colour-white;
								color: $colour-active-blue;
							}
						}
					}
				}
				.copyright {
					display: inline-block;
					margin: 10px 0 0;
					background-color: transparent;
					padding: 0;
				}
			}
		}
	}
	ul, div.footer.content {
		display: block;
		position: relative;
		z-index: 1;
		white-space: normal;
		max-width: 800px;
		margin: 0 0 10px;
		padding-bottom: 0;
		@media (max-width: 767px) {
			display: block;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
		}
		li {
			list-style-type: none;
			margin: 0;
			padding: 0;
			display: inline-block;
			a {
				text-decoration: none;
				color: #fff;
				margin: 0;
				padding: 0;
				&:hover {
					text-decoration: none;
				}
				&:after {
					content: '|';
					padding-left: 10px;
					padding-right: 10px;
				}
			}
			&:last-child {
				a {
					&:after {
						content: '';
					}
				}
			}
		}
	}
	ul {
		padding-left: 20px;
	}
}

// Blog page index styling

html body.mpblog-category-view,
html body.mpblog-month-view,
html body.mpblog-post-index {
	main#maincontent {
		div.columns {
			display: flex;
			div.column.main {
				float: none;
				width: 100%;
				order: 2;
			}
			div.sidebar.sidebar-main {
				float: none;
				width: 100%;
				order: 1;
				.mpblog-search {
					float: right !important;
					max-width: 200px;
				}
				.mp-sidebar.monthly {
					float: left;
					width: auto;
					div.row {
						div.block-title,
						div.block-content {
							display: inline-block;
						}
					}
				}
				.mp-sidebar.category {
					float: left;
					width: 100%;
					ul {
						margin: 0;
						padding: 0;
						li {
							list-style-type: none;
							display: inline-block;
							a {
								i {
									display: none;
								}
							}
						}
					}
				}
			}
			div.pager {
				float: left;
				width: 100%;
			}
		}
	}
	#mpblog-list-container {
		div.post-list-body {
			div.post-list-item {
				margin-bottom: 40px;
				height: 510px;
				.post-item-wraper {
					height: 100%;
					border: 2px solid #e0e0e0;
					&:hover {
						box-shadow: none;
						border-color: #b1b1b1;
					}
					a.mp-read-more {
						&:hover {
							font-weight: 700;
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}

// Blog page excessive title text fix

.post-info-wraper h2.mp-post-title a {
	font-size: 16px;
	line-height: 20px;
	display: block;
}

// Menu changing icon on open

html.nav-open {
	.nav-toggle:before {
		content: '\e616';
		color: #000000;
	}
}

html .nav-toggle:before {
	color: #000000;
}

html .nav-toggle {
	top: 10px;
}

// Mobile logo to be centered

html body header.page-header a.logo,
html body header.page-header strong.logo {
	@media (max-width: 767px) {
		position: relative;
		left: 49%;
		transform: translateX(-51%);
		margin: 0 0 10px;
	}
}

// Nav breadcrumbs reworked

html body .nav-sections {
	margin-bottom: 0;
}

html body .page-wrapper div.breadcrumbs {
	background: #151524;
	width: 100%;
	max-width: none;
	padding: 0;
	ul.items {
		margin-left: auto;
		margin-right: auto;
		max-width: 1220px;
		padding: 10px 0;
		li {
			color: #fff;
			a {
				color: #fff;
				font-weight: 300;
			}
		}
		@media (max-width: 1295px) {
			width: calc(100% - 60px);
			max-width: 100%;
		}
	}
}

// Mobile navigation styling

@media only screen and (max-width: 767px) {
	html body div.page-wrapper {
		div.sections.nav-sections {
			top: 61px;
			box-shadow: none;
			border-top: 1px solid #9a9a9a;
			width: 100%;
			z-index: 500000000;
		}
		.nav-sections-item-title {
			width: 50%;
		}
	}
	html.nav-open .page-wrapper {
		left: 0;
	}
	html .nav-sections {
		background: #ffffff;
		left: calc(-1 * (100% - 0px));
		div.section-items.nav-sections-items {
			div.section-item-title.nav-sections-item-title {
				background: #ebebeb;
				border: 0;
				cursor: pointer;
				a {
					color: #565656;
					font-size: 14px;
				}
				&.active {
					background: #fff;
					border: 0;
					a {
						color: #000;
						font-weight: 700;
					}
				}
			}
		}
		div.section-item-content.nav-sections-item-content {
			nav.navigation {
				background: transparent;
				.parent .level-top:after {
					content: '\e616';
					transform: rotate(45deg);
					font-size: 10px;
					line-height: 24px;
					right: 15px;
				}
				.parent .level-top.ui-state-active:after {
					content: '-';
					transform: none;
					font-size: 30px;
					line-height: 12px;
				}
				li.level0 {
					//border-left: 5px solid transparent;
					ul.level0 {
						background: $colour-grey;
						li a.ui-state-focus {
							font-weight: 300;
						}
						li.all-category a {
							padding-left: 20px;
						}
						li a {
							padding-left: 25px;
						}
						li.level1 ul li {
							border-bottom: 1px solid #ccc;
							margin-left: 20px;
							margin-right: 20px;
							&:last-child {
								border: 0;
							}
							a {
								font-size: 12px;
								color: #5f5f5f;
								padding-left: 5px;
							}
						}

					}
				
				}
				li.level0.active {
					border-left: 5px solid #151525;
				}
				a {
					background: transparent;
					color: #000;
					font-size: 14px;
					border-color: transparent;
					&.ui-state-active,
					&.ui-state-focus {
						font-weight: 700;
						border-left: 0
					}
				}
				.level0 > .level-top {
					text-transform: none;

				}
				.level0:first-child {
					border-top: 0;
				}
				.level0:last-child {
					border-bottom: 0;
				}
			}
		}
	}

}

// Fix for products on single row displaying in center and not to left

@media (min-width: 768px) {
	.page-products .products-grid .product-item:nth-child(4n + 1) {
		margin-right: 0;
		margin-left: 0;
	}
}

// Making related products on product level full-width

html body .block.related .block-content.content {
    background: #ebebeb;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin: 0 -50vw;
    padding: 30px 0;
    div.block-actions,
    div.products.wrapper.grid {
    	display: block;
    	position: relative;
    	max-width: 1280px;
    	margin: 0 auto;
    }
}

html body.catalog-product-view {
	div.page-wrapper {
		overflow: hidden;
	}
}

html body div.column.main div.block.related div.block-title.title strong {
	display: block;
	clear: both;
	padding-top: 50px;
}

// Mobile nav highlighting of active pages

@media only screen and (max-width: 767px) {
	.navigation .level0 > .level-top,
	.navigation .level0 > .level1 {
	    font-weight: 300;
	}
}

@media only screen and (max-width: 767px) {
	.navigation .level0.active > a:not(.ui-state-active),
	.navigation .level0.has-active > a:not(.ui-state-active),
	.navigation .submenu:not(:first-child) .active > a {
	    border-color: #eb3e32;
	    border-style: solid;
	    border-width: 0 0 0 8px;
	    color: #333333;
	    font-weight: 700;
	}
}

// Product page styling

body.catalog-product-view {
	h1.page-title {
		color: #000000;
	}
	div.column.main {
		margin-top: 30px;
	}
	div.product-info-price {
		div.price-box.price-final_price,
		div.product-info-stock-sku {
			display: block;
			position: relative;
			text-align: left;
			width: 100%;
			overflow: hidden;
			padding-left: 0;
		}
		.price-box.price-final_price span.special-price:after {
			content: 'OFFER';
			width: 110px;
			height: 40px;
			text-align: center;
			display: block;
			background: $colour-active-red;
			color: #fff;
			padding: 13px 0 13px 14px;
			top: -30px;
			right: -50px;
			position: absolute;
			transform: rotate(45deg);
			line-height: 82px;
			font-weight: 700;
			z-index: 1;
			left: auto;
		}
		.available div {
		    color: #51c47d;
		}
		.price-box .price-container .price,
		.price-box .price-container .price-label {
			color: #000000;
		}
	}
	.box-tocart .action.tocart,
	button[type="submit"] {
		background: $colour-bluegrey;
		border: 0;
		border-radius: 0;
		max-width: 200px;
		box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
		width: auto;
		height: 45px;
		line-height: 1;
		padding: 0 40px;
		font-size: 14px;
		font-weight: 300;
		font-family: $font-primary;
		transition: all 0.3s ease-in-out;
		&:hover {
			background: $colour-button-hover;
			font-weight: 300;
		}
	}
}


// Footer dupe menu fix

div.footer.content ul.footer.links:first-child {
    display: none;
}