/** Typography **/
/** Colours **/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

img.full-width {
  width: 100%; }

* {
  font-family: "Roboto", sans-serif; }

/** Buttons **/
button.primary {
  padding: 15px 40px; }
  button.primary.action {
    padding: 15px 40px;
    background: #151524;
    color: #fff; }
    button.primary.action:hover {
      background-color: #1d2c51; }
    button.primary.action.subscribe {
      padding: 7px 15px; }

/** Filter and Pagination **/
.pages .action {
  box-shadow: none;
  border-radius: 0;
  color: #fff;
  background: #151524; }
  .pages .action.next:before {
    color: #fff; }
  .pages .action:hover {
    background-color: #1d2c51; }
    .pages .action:hover.next:before {
      color: #fff; }

.pages .pages-items {
  padding: 0 10px; }
  .pages .pages-items .item a,
  .pages .pages-items .item strong.page {
    color: #151524;
    padding: 0 10px; }
  .pages .pages-items .item.current a,
  .pages .pages-items .item.current strong, .pages .pages-items .item:hover a,
  .pages .pages-items .item:hover strong {
    background-color: #1d2c51;
    color: #fff; }

.field .toolbar select {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0; }

.modes .modes-mode {
  background-color: transparent; }

.products.products-grid .product-item-info:hover, .products.products-grid .product-item-info.active {
  box-shadow: 1px 0 2px 0 rgba(0, 0, 0, 0.3);
  margin: 0; }
  .products.products-grid .product-item-info:hover .product-item-inner, .products.products-grid .product-item-info.active .product-item-inner {
    box-shadow: none;
    margin-top: 10px; }

.products .product {
  text-align: center; }
  .products .product .product-item-inner {
    display: block;
    position: relative !important;
    box-shadow: none;
    border: 0 none;
    width: auto !important;
    height: auto !important;
    margin: 0;
    padding: 0; }
    .products .product .product-item-inner .product-item-actions .actions-primary,
    .products .product .product-item-inner .product-item-actions .actions-secondary {
      display: block;
      text-align: center;
      margin: 10px auto; }

@media (min-width: 1024px), print {
  .page-products .products-grid .product.product-item {
    margin-left: calc((100% - 4 * 25%) / 3);
    padding: 0;
    width: 25%;
    padding: 0 20px; } }

.page-products .products-grid .product.product-item .product-item-info {
  padding: 0 20px 20px;
  box-shadow: 1px 0 2px 0 transparent;
  border: 1px solid transparent; }
  .page-products .products-grid .product.product-item .product-item-info:hover, .page-products .products-grid .product.product-item .product-item-info:active {
    box-shadow: none;
    border: 1px solid #bbbbbb; }
  .page-products .products-grid .product.product-item .product-item-info:not(:hover):not(.active) .product-item-inner {
    margin: 0;
    height: 0; }
  .page-products .products-grid .product.product-item .product-item-info .swatch-option {
    display: inline-block;
    text-align: center;
    float: none; }

@media (min-width: 768px), print {
  .page-wrapper .breadcrumbs {
    padding: 10px 0 10px 20px; } }

header.page-header .panel.wrapper {
  background-color: #a90000; }

header.page-header .logo {
  max-width: 204px; }

@media (min-width: 768px), print {
  .nav-sections .navigation .level0.active > .level-top,
  .nav-sections .navigation .level0.has-active > .level-top {
    border-color: #a90000; } }

@media (min-width: 768px), print {
  body .page-footer {
    padding: 40px 20px;
    background: #363f4d; }
    body .page-footer .footer.content .links li {
      margin-bottom: 14px; }
      body .page-footer .footer.content .links li a,
      body .page-footer .footer.content .links li a:visited {
        color: #fff; }
  .footer-payment-methods {
    background: #151524; }
    .footer-payment-methods .content {
      padding: 20px; }
      .footer-payment-methods .content .payment-methods li {
        display: inline-block;
        margin-left: 5px; }
        .footer-payment-methods .content .payment-methods li:first-child {
          margin-left: 0; }
        .footer-payment-methods .content .payment-methods li i {
          display: block;
          cursor: default;
          padding: 4px;
          font-size: 26px;
          line-height: 1;
          background: 0 0;
          border: 1px solid #fff;
          border-radius: 3px;
          color: #fff;
          transition: all .5s; }
          .footer-payment-methods .content .payment-methods li i:hover {
            background-color: #fff;
            color: #1d2c51; }
      .footer-payment-methods .content .copyright {
        display: inline-block;
        margin: 10px 0 0;
        background-color: transparent;
        padding: 0; } }

div.product-item-details div.product.options dl.product.options dt.label, div.product-item-details div.product.options dl.product.options dd.values {
  width: 49%;
  display: inline-block;
  margin-bottom: 0; }
  @media (max-width: 768px) {
    div.product-item-details div.product.options dl.product.options dt.label, div.product-item-details div.product.options dl.product.options dd.values {
      width: 35%; } }

div.product-item-details div.product.options dl.product.options dt.label {
  font-weight: 700; }

div.product-item-details div.product.options dl.product.options dd.values {
  font-weight: 300; }

@media (min-width: 768px) {
  div#checkout ul.opc-progress-bar li.opc-progress-bar-item.final-completion:before {
    width: 50%; }
  div#checkout ul.opc-progress-bar li.opc-progress-bar-item.final-completion > span:after {
    border-color: #ff5501;
    content: '\e610';
    font-family: 'luma-icons'; }
  div#checkout ul.opc-progress-bar li.opc-progress-bar-item.final-completion > span:before {
    background: #ff5400;
    border: 1px solid #ffa77c; } }

body.page-layout-checkout ol#checkoutSteps li#shipping div.step-title {
  border-bottom: 0; }
  body.page-layout-checkout ol#checkoutSteps li#shipping div.step-title span,
  body.page-layout-checkout ol#checkoutSteps li#shipping div.step-title div.primary {
    display: inline-block; }
  body.page-layout-checkout ol#checkoutSteps li#shipping div.step-title div.primary {
    float: right; }
    body.page-layout-checkout ol#checkoutSteps li#shipping div.step-title div.primary button {
      margin: -10px 0 0 0px; }
      @media (max-width: 767px) {
        body.page-layout-checkout ol#checkoutSteps li#shipping div.step-title div.primary button {
          margin: -5px 0 0 0px; } }
    @media (max-width: 639px) {
      body.page-layout-checkout ol#checkoutSteps li#shipping div.step-title div.primary #co-shipping-method-form {
        border: 0;
        background: transparent;
        padding: 0;
        margin: 0; } }
  @media (max-width: 639px) {
    body.page-layout-checkout ol#checkoutSteps li#shipping div.step-title hr {
      margin-top: 35px; } }

body.page-layout-checkout .opc-block-summary .minicart-items-wrapper {
  max-height: 100%; }

@media (min-width: 768px) {
  body.checkout-cart-index div.cart-container {
    display: flex;
    flex-wrap: wrap; }
    body.checkout-cart-index div.cart-container div.cart-summary {
      top: 0; }
    body.checkout-cart-index div.cart-container .cart-discount {
      order: 2; }
    body.checkout-cart-index div.cart-container .block.crosssell {
      margin-top: 0; }
      body.checkout-cart-index div.cart-container .block.crosssell .products {
        margin-top: 0; } }

body.checkout-index-index .authentication-wrapper {
  display: none; }

body.checkout-index-index hr {
  margin-top: 30px; }

body.checkout-index-index li#shipping .primary {
  float: right;
  margin-top: 0; }

body.checkout-index-index .opc-wrapper .step-title {
  border: 0; }

@media (max-width: 639px) {
  .opc-wrapper .methods-shipping {
    border-bottom: 0;
    background: transparent;
    margin-top: -35px; } }

.column.main ol.product-items.widget-product-grid,
.column.main ol.product-items.widget-new-grid,
.column.main ol.product-items {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  position: relative; }
  .column.main ol.product-items.widget-product-grid li.product-item,
  .column.main ol.product-items.widget-new-grid li.product-item,
  .column.main ol.product-items li.product-item {
    display: flex;
    padding: 0px; }
    .column.main ol.product-items.widget-product-grid li.product-item div.product-item-info,
    .column.main ol.product-items.widget-new-grid li.product-item div.product-item-info,
    .column.main ol.product-items li.product-item div.product-item-info {
      padding: 10px;
      margin: 0 auto;
      max-width: 240px; }
      .column.main ol.product-items.widget-product-grid li.product-item div.product-item-info button.primary.action,
      .column.main ol.product-items.widget-new-grid li.product-item div.product-item-info button.primary.action,
      .column.main ol.product-items li.product-item div.product-item-info button.primary.action {
        padding: 10px 15px;
        background: #363f4c;
        color: #fff;
        font-weight: 300;
        width: auto;
        border: 0; }
    @media (max-width: 1024px) {
      .column.main ol.product-items.widget-product-grid li.product-item,
      .column.main ol.product-items.widget-new-grid li.product-item,
      .column.main ol.product-items li.product-item {
        width: 49% !important;
        margin: 0 auto 10px; } }
    @media (max-width: 580px) {
      .column.main ol.product-items.widget-product-grid li.product-item,
      .column.main ol.product-items.widget-new-grid li.product-item,
      .column.main ol.product-items li.product-item {
        width: 100% !important;
        margin: 0 auto 10px; } }

@media (min-width: 640px) {
  .products-grid .product-item-actions {
    margin: 0;
    white-space: nowrap; }
    .products-grid .product-item-actions .actions-primary {
      display: inline-block;
      width: 80%;
      overflow: hidden;
      text-align: left; }
    .products-grid .product-item-actions .actions-secondary {
      display: inline-block;
      padding-left: 10px;
      width: calc(20% - 12px);
      vertical-align: top; }
  .product-item-actions .actions-primary + .actions-secondary > .action {
    display: block; }
  .block.widget .products-grid .product-item,
  .page-layout-1column .block.widget .products-grid .product-item,
  .page-layout-3columns .block.widget .products-grid .product-item {
    width: 32%; }
  .products-grid .product-item-info {
    padding: 0px;
    margin: 0;
    border: 1px solid transparent; }
  .products-grid .product-item-info:hover,
  .products-grid .product-item-info.active {
    background: transparent;
    box-shadow: none;
    border: 1px solid #bbbbbb;
    margin: 0px;
    position: relative;
    z-index: 2; } }

@media (min-width: 768px) {
  .page-layout-1column .block.widget .products-grid .product-item {
    width: 33% !important; } }

@media (min-width: 1024px) {
  .page-layout-1column .block.widget .products-grid .product-item {
    width: 20% !important; } }

.products-grid .product-item {
  display: inline-block;
  width: 49%; }

div.product-item-info {
  z-index: 3; }

.no-display {
  display: none !important; }

button.action.toggle.change {
  display: none !important; }

body.checkout-onepage-success div.column.main {
  display: flex;
  flex-direction: column; }
  body.checkout-onepage-success div.column.main .checkout-success {
    order: 2;
    margin-top: 20px; }
  body.checkout-onepage-success div.column.main div#registration {
    order: 1; }
    body.checkout-onepage-success div.column.main div#registration br {
      display: none; }

nav.navigation {
  z-index: 5; }

.menu .rightstatic {
  float: right;
  max-width: 400px; }

#om ul {
  padding-left: 0; }

#om ul ul.normal-sub {
  padding: 10px 20px; }

.menu > ul > li {
  background: transparent; }

.menu > ul > li a {
  color: #000;
  padding: 15px 30px; }

.menu > ul > li > ul {
  background: #ffffff;
  border: 1px solid #ccc; }

.menu > ul > li > ul > li {
  margin: 0 1%; }

.menu > ul > li > ul > li a {
  padding: 0px 10px 10px; }

.menu > ul > li > ul > li > ul > li {
  padding: 10px 0 0;
  transition: all 0.3s ease-in-out; }
  .menu > ul > li > ul > li > ul > li:hover {
    background: #e6e6e6; }

body.cms-home .page-wrapper {
  overflow: hidden; }

body.cms-home .columns .column.main {
  padding-bottom: 0; }

div#catewithimg {
  background: #ebebeb;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding: 30px 0; }
  div#catewithimg .block .title {
    margin-bottom: 40px; }
  div#catewithimg.cwiw div.row {
    max-width: 1200px;
    margin: 40px auto 30px; }
  div#catewithimg .quick-shop-item {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 130px;
    padding: 10px 20px;
    margin: 10px;
    background: #fff;
    outline: 3px solid #fff;
    transition: all 0.3s ease-in-out; }
    div#catewithimg .quick-shop-item:hover {
      background: #f3f3f3; }
    div#catewithimg .quick-shop-item a {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 20px; }
      div#catewithimg .quick-shop-item a h3 {
        margin: 20px 0 0 0;
        color: #000;
        font-size: 16px;
        font-weight: 700; }

div.block.widget.block-products-list.grid div.block-title,
div.block.widget.block-new-products.grid div.block-title {
  text-align: center;
  position: relative; }
  div.block.widget.block-products-list.grid div.block-title:after,
  div.block.widget.block-new-products.grid div.block-title:after {
    content: '';
    height: 1px;
    width: 100%;
    border-top: 1px solid #ccc;
    top: 13px;
    display: block;
    position: absolute; }
  div.block.widget.block-products-list.grid div.block-title strong,
  div.block.widget.block-new-products.grid div.block-title strong {
    z-index: 1;
    background: #fff;
    padding-left: 15px;
    padding-right: 15px;
    position: relative; }

.bg.bg-lightgrey {
  background: #ebebeb;
  padding: 40px 0;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw; }
  .bg.bg-lightgrey h2 {
    margin-top: 0; }
  .bg.bg-lightgrey a {
    color: #000;
    text-decoration: underline; }
    .bg.bg-lightgrey a:hover {
      font-weight: 700; }
  .bg.bg-lightgrey p {
    width: 50%;
    margin: 0 auto 20px;
    max-width: 600px; }
    @media (max-width: 780px) {
      .bg.bg-lightgrey p {
        width: 80%; } }

li.product-item,
li.product-item div.product-item-info {
  position: relative;
  overflow: hidden; }

.price-box.price-final_price span.special-price:after {
  content: 'OFFER';
  width: 120px;
  height: 80px;
  text-align: center;
  display: block;
  background: #eb3e32;
  color: #fff;
  padding: 16px 0 16px 20px;
  top: -53px;
  left: -82px;
  position: absolute;
  transform: rotate(-45deg);
  line-height: 160px;
  font-weight: 700;
  z-index: 1; }

.products-grid .product-item-info {
  border: 2px solid #eeeeee; }
  .products-grid .product-item-info:hover {
    border: 2px solid #eb3e32; }
    .products-grid .product-item-info:hover div.product-item-details {
      color: #eb3e32; }
      .products-grid .product-item-info:hover div.product-item-details strong a,
      .products-grid .product-item-info:hover div.product-item-details span.price-label,
      .products-grid .product-item-info:hover div.product-item-details span.price-wrapper {
        color: #eb3e32; }
  .products-grid .product-item-info div.product-item-details {
    text-align: center; }
    .products-grid .product-item-info div.product-item-details div.product-reviews-summary {
      display: none; }
    .products-grid .product-item-info div.product-item-details .price-box {
      margin: 0;
      height: 45px; }
      .products-grid .product-item-info div.product-item-details .price-box span.special-price,
      .products-grid .product-item-info div.product-item-details .price-box span.old-price {
        display: block;
        text-align: center;
        margin: 0; }
      .products-grid .product-item-info div.product-item-details .price-box span.price {
        font-weight: 300;
        font-size: 12px; }
    .products-grid .product-item-info div.product-item-details .product-item-name {
      text-align: center;
      margin: 0; }
      .products-grid .product-item-info div.product-item-details .product-item-name a {
        font-weight: 700; }
  .products-grid .product-item-info span.product-image-wrapper {
    padding-bottom: 90% !important; }

.products-grid .product-item-actions {
  display: flex;
  flex-direction: column; }
  .products-grid .product-item-actions div.actions-primary {
    order: 2;
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: center; }
  .products-grid .product-item-actions div.actions-secondary {
    order: 1;
    width: 100%;
    display: block;
    margin: 0 auto 10px;
    text-align: center;
    padding-left: 0; }
    .products-grid .product-item-actions div.actions-secondary a {
      display: inline-block !important;
      margin: 0 !important;
      padding: 0 !important; }

div.widget.block.block-banners {
  position: relative;
  display: block;
  overflow: hidden; }

div.widget.block.block-banners.logo-slider {
  margin: 0 auto; }
  div.widget.block.block-banners.logo-slider img {
    width: auto;
    margin: 0 auto; }
  div.widget.block.block-banners.logo-slider div.slick-list {
    max-width: 90%;
    margin: 0 auto;
    position: relative;
    display: block; }
  div.widget.block.block-banners.logo-slider button.slick-prev {
    font-size: 0;
    border: 0;
    box-shadow: none;
    color: #bfbfbf;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 3%;
    height: auto;
    line-height: 1;
    background: transparent;
    transition: all 0.3s ease-in-out; }
    div.widget.block.block-banners.logo-slider button.slick-prev:before {
      content: '<';
      font-size: 32px;
      font-weight: 700; }
    div.widget.block.block-banners.logo-slider button.slick-prev:hover {
      background: transparent;
      color: #888888; }
    @media (max-width: 768px) {
      div.widget.block.block-banners.logo-slider button.slick-prev {
        left: 0; } }
  div.widget.block.block-banners.logo-slider button.slick-next {
    font-size: 0;
    border: 0;
    box-shadow: none;
    color: #bfbfbf;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 3%;
    height: auto;
    line-height: 1;
    background: transparent;
    transition: all 0.3s ease-in-out; }
    div.widget.block.block-banners.logo-slider button.slick-next:before {
      content: '>';
      font-size: 32px;
      font-weight: 700; }
    div.widget.block.block-banners.logo-slider button.slick-next:hover {
      background: transparent;
      color: #888888; }
    @media (max-width: 768px) {
      div.widget.block.block-banners.logo-slider button.slick-next {
        right: 0; } }

body.page-products div.category-view {
  display: none; }

body.page-products .products.products-grid .product.product-item .product-item-info:hover {
  border-color: #eb3e32; }
  body.page-products .products.products-grid .product.product-item .product-item-info:hover .product-item-inner {
    margin-top: 0; }

ul.slick-dots {
  position: absolute;
  bottom: 20px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  padding: 0; }
  ul.slick-dots li {
    display: inline-block;
    margin: 0;
    padding: 0; }
    ul.slick-dots li button {
      font-size: 0;
      border-radius: 50%;
      padding: 6px;
      line-height: 1;
      background: #fff;
      margin: 0 3px; }
    ul.slick-dots li.slick-active button {
      outline: 3px solid #777;
      outline-offset: -9px; }

div.slick-slider img {
  width: calc(100% - 9px); }
  @media (max-width: 768px) {
    div.slick-slider img {
      width: calc(100% - 4px); } }

div.slick-slider .widget.block.block-static-block {
  margin-bottom: 0;
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
  color: #fff; }
  div.slick-slider .widget.block.block-static-block p {
    max-width: 40%; }
  div.slick-slider .widget.block.block-static-block a {
    padding: 10px 15px;
    background: #fff;
    color: #000;
    font-weight: 300;
    width: auto;
    border: 0;
    font-size: 1.3rem;
    border-radius: 0;
    line-height: 1;
    cursor: pointer;
    margin-top: 10px;
    position: relative;
    display: inline-block;
    text-decoration: none;
    transition: all 0.3s ease-in-out; }
    div.slick-slider .widget.block.block-static-block a:hover {
      text-decoration: none;
      color: #fff;
      background: #000;
      outline: 0; }

body.cms-home p.logo-slider-intro {
  text-align: center !important;
  background: #253346;
  padding: 40px 0 20px;
  color: #fff;
  margin-bottom: 0;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw; }
  @media (max-width: 768px) {
    body.cms-home p.logo-slider-intro {
      padding: 20px;
      width: 100%; } }

body.cms-home div.widget.block.block-banners.logo-slider {
  text-align: center !important;
  background: #253346;
  padding: 50px 0;
  color: #fff;
  margin-top: 0;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw; }

@media (max-width: 768px) {
  body.cms-home div.widget.block.block-banners.main-header div.slick-slide img {
    width: auto;
    height: auto;
    min-height: 300px; }
  body.cms-home div.widget.block.block-banners.main-header div.slick-slide .widget.block.block-static-block p {
    max-width: 65%; } }

body.cms-home .widget.block.block-static-block.small-block,
body.cms-home .widget.block.block-static-block.large-block {
  width: auto;
  display: inline-block;
  vertical-align: top;
  max-height: 270px;
  background-size: cover;
  background-repeat: no-repeat;
  height: 270px;
  margin-top: -40px; }
  @media (max-width: 768px) {
    body.cms-home .widget.block.block-static-block.small-block,
    body.cms-home .widget.block.block-static-block.large-block {
      margin-top: -30px; } }
  body.cms-home .widget.block.block-static-block.small-block.small-block,
  body.cms-home .widget.block.block-static-block.large-block.small-block {
    width: 33%; }
    @media (max-width: 580px) {
      body.cms-home .widget.block.block-static-block.small-block.small-block,
      body.cms-home .widget.block.block-static-block.large-block.small-block {
        width: 100%; } }
  body.cms-home .widget.block.block-static-block.small-block.large-block,
  body.cms-home .widget.block.block-static-block.large-block.large-block {
    width: 65%;
    margin-left: 1%; }
    body.cms-home .widget.block.block-static-block.small-block.large-block p,
    body.cms-home .widget.block.block-static-block.large-block.large-block p {
      max-width: 60%; }
    @media (max-width: 580px) {
      body.cms-home .widget.block.block-static-block.small-block.large-block,
      body.cms-home .widget.block.block-static-block.large-block.large-block {
        width: 100%;
        margin-top: -10px;
        margin-left: 0; }
        body.cms-home .widget.block.block-static-block.small-block.large-block p,
        body.cms-home .widget.block.block-static-block.large-block.large-block p {
          max-width: 100%; } }
  body.cms-home .widget.block.block-static-block.small-block div.block-wrap,
  body.cms-home .widget.block.block-static-block.large-block div.block-wrap {
    color: #fff;
    padding: 30px; }
    @media (max-width: 768px) {
      body.cms-home .widget.block.block-static-block.small-block div.block-wrap,
      body.cms-home .widget.block.block-static-block.large-block div.block-wrap {
        padding: 0 20px; } }
  body.cms-home .widget.block.block-static-block.small-block a,
  body.cms-home .widget.block.block-static-block.large-block a {
    padding: 10px 15px;
    background: #fff;
    color: #000;
    font-weight: 300;
    width: auto;
    border: 0;
    font-size: 1.3rem;
    border-radius: 0;
    line-height: 1;
    cursor: pointer;
    margin-top: 10px;
    position: relative;
    display: inline-block;
    text-decoration: none;
    transition: all 0.3s ease-in-out; }
    body.cms-home .widget.block.block-static-block.small-block a:hover,
    body.cms-home .widget.block.block-static-block.large-block a:hover {
      text-decoration: none;
      color: #fff;
      background: #000;
      outline: 0; }

.message.global.cookie {
  z-index: 100; }

p.full-width-image-block {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw; }
  p.full-width-image-block img {
    width: 100%; }

body.checkout-index-index header.page-header div.panel.wrapper {
  display: none; }

body.checkout-index-index header.page-header div.header.content div.block.block-search,
body.checkout-index-index header.page-header div.header.content div.minicart-wrapper {
  display: none; }

body.checkout-index-index footer.page-footer {
  display: none; }

@media only screen and (max-width: 767px) {
  .nav-open .nav-toggle:after {
    background: rgba(0, 0, 0, 0.7);
    content: '';
    display: block;
    height: calc(100% - 68px);
    position: fixed;
    right: 0;
    top: auto;
    bottom: 0;
    width: 100%;
    z-index: 1; } }

@media only screen and (min-width: 640px) and (max-width: 767px) {
  .nav-open .nav-toggle:after {
    height: calc(100% - 61px); } }

body.page-products div.columns div.column.main div.products.products-list ol.products.list {
  display: block; }
  body.page-products div.columns div.column.main div.products.products-list ol.products.list li.item.product.product-item {
    display: block; }
    @media (max-width: 1024px) {
      body.page-products div.columns div.column.main div.products.products-list ol.products.list li.item.product.product-item {
        width: 100% !important; } }
    @media (max-width: 640px) {
      body.page-products div.columns div.column.main div.products.products-list ol.products.list li.item.product.product-item div.product-item-info a,
      body.page-products div.columns div.column.main div.products.products-list ol.products.list li.item.product.product-item div.product-item-info div.product.details.product-item-details,
      body.page-products div.columns div.column.main div.products.products-list ol.products.list li.item.product.product-item div.product-item-info .product-image-container {
        display: block;
        width: 100% !important; } }
    body.page-products div.columns div.column.main div.products.products-list ol.products.list li.item.product.product-item .product {
      text-align: left; }
      body.page-products div.columns div.column.main div.products.products-list ol.products.list li.item.product.product-item .product .product-item-inner .product-item-actions .actions-primary,
      body.page-products div.columns div.column.main div.products.products-list ol.products.list li.item.product.product-item .product .product-item-inner .product-item-actions .actions-secondary {
        text-align: left; }

html body footer.page-footer {
  padding: 20px 0;
  overflow: hidden;
  background: #363f4d; }
  html body footer.page-footer div.footer.wrap {
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%; }
    @media (max-width: 1024px) {
      html body footer.page-footer div.footer.wrap {
        width: 90%;
        margin: 0 auto;
        padding: 0; } }
  html body footer.page-footer div.footer.content {
    display: inline-block;
    width: 64%;
    vertical-align: top;
    border: 0; }
    @media (max-width: 1024px) {
      html body footer.page-footer div.footer.content {
        width: auto; } }
    @media (max-width: 767px) {
      html body footer.page-footer div.footer.content {
        display: block;
        width: 100%;
        margin: 0 auto;
        padding: 0; } }
    html body footer.page-footer div.footer.content ul.footer.links li.nav.item {
      font-size: 22px;
      line-height: 24px;
      display: inline-block;
      vertical-align: top;
      margin-right: 50px;
      background: transparent;
      border: 0;
      color: #fff; }
      html body footer.page-footer div.footer.content ul.footer.links li.nav.item a {
        border-bottom: 3px solid #fff;
        display: inline-block;
        margin-bottom: 10px;
        color: #fff; }
        html body footer.page-footer div.footer.content ul.footer.links li.nav.item a:hover {
          text-decoration: none; }
      html body footer.page-footer div.footer.content ul.footer.links li.nav.item ul li.nav.item {
        border: 0;
        font-size: 14px;
        display: block;
        line-height: 16px;
        margin-bottom: 0; }
        html body footer.page-footer div.footer.content ul.footer.links li.nav.item ul li.nav.item a {
          border: 0;
          display: inline-block;
          margin: 0; }
          html body footer.page-footer div.footer.content ul.footer.links li.nav.item ul li.nav.item a:hover {
            text-decoration: underline; }
      @media (max-width: 1024px) {
        html body footer.page-footer div.footer.content ul.footer.links li.nav.item {
          margin-right: 10px; } }
      @media (max-width: 767px) {
        html body footer.page-footer div.footer.content ul.footer.links li.nav.item {
          width: 48%;
          margin: 0;
          padding: 0;
          text-align: center; }
          html body footer.page-footer div.footer.content ul.footer.links li.nav.item ul {
            display: block;
            margin: 0 auto; }
            html body footer.page-footer div.footer.content ul.footer.links li.nav.item ul li {
              margin: 0 auto; } }
      @media (max-width: 560px) {
        html body footer.page-footer div.footer.content ul.footer.links li.nav.item {
          width: 100%;
          margin: 0 auto 20px; }
          html body footer.page-footer div.footer.content ul.footer.links li.nav.item li.nav.item a {
            padding: 2px 0; } }
    html body footer.page-footer div.footer.content p a {
      margin-right: 20px; }
      html body footer.page-footer div.footer.content p a:hover img {
        transition: all 0.3s ease-in-out;
        opacity: 0.7; }
    @media (max-width: 767px) {
      html body footer.page-footer div.footer.content p {
        text-align: center;
        margin: 0 5px; }
        html body footer.page-footer div.footer.content p a {
          margin: 0 5px; } }
  html body footer.page-footer div.footer.email {
    display: inline-block;
    width: 30%;
    vertical-align: top;
    color: #fff;
    height: 100%; }
    html body footer.page-footer div.footer.email .block.newsletter .field {
      min-width: 250px; }
      @media (max-width: 1024px) {
        html body footer.page-footer div.footer.email .block.newsletter .field {
          display: block;
          min-width: 220px; } }
    @media (max-width: 1024px) {
      html body footer.page-footer div.footer.email .block.newsletter .actions {
        display: block;
        text-align: right;
        width: 100%; } }
    html body footer.page-footer div.footer.email h2 {
      font-size: 22px;
      line-height: 24px;
      border-bottom: 3px solid #fff;
      display: inline-block;
      padding-bottom: 10px;
      margin-top: 30px; }
    html body footer.page-footer div.footer.email p {
      margin-bottom: 20px; }
    @media (max-width: 767px) {
      html body footer.page-footer div.footer.email {
        display: block;
        width: 100%;
        text-align: center; }
        html body footer.page-footer div.footer.email .block.newsletter .form.subscribe {
          display: block;
          width: 100%;
          max-width: 350px;
          text-align: center;
          margin: 0 auto; }
        html body footer.page-footer div.footer.email .block.newsletter .actions {
          text-align: center; } }

html body div.footer-payment-methods {
  background: #151524;
  width: 100%;
  position: relative;
  display: block;
  overflow: hidden; }
  html body div.footer-payment-methods div.payment-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    white-space: nowrap;
    position: relative;
    overflow: hidden; }
    @media (max-width: 767px) {
      html body div.footer-payment-methods div.payment-wrapper {
        max-width: none;
        width: 100%;
        padding: 0;
        margin: 0;
        white-space: normal;
        overflow: hidden;
        background: #151524; }
        html body div.footer-payment-methods div.payment-wrapper .content {
          padding: 20px; }
          html body div.footer-payment-methods div.payment-wrapper .content .payment-methods {
            margin: 0 auto 20px;
            display: block;
            text-align: center; }
            html body div.footer-payment-methods div.payment-wrapper .content .payment-methods li {
              display: inline-block;
              text-align: center;
              margin-left: 5px; }
              html body div.footer-payment-methods div.payment-wrapper .content .payment-methods li:first-child {
                margin-left: 0; }
              html body div.footer-payment-methods div.payment-wrapper .content .payment-methods li i {
                display: block;
                cursor: default;
                padding: 4px;
                font-size: 26px;
                line-height: 1;
                background: 0 0;
                border: 1px solid #fff;
                border-radius: 3px;
                color: #fff;
                transition: all .5s; }
                html body div.footer-payment-methods div.payment-wrapper .content .payment-methods li i:hover {
                  background-color: #fff;
                  color: #1d2c51; }
          html body div.footer-payment-methods div.payment-wrapper .content .copyright {
            display: inline-block;
            margin: 10px 0 0;
            background-color: transparent;
            padding: 0; } }
  html body div.footer-payment-methods ul, html body div.footer-payment-methods div.footer.content {
    display: block;
    position: relative;
    z-index: 1;
    white-space: normal;
    max-width: 800px;
    margin: 0 0 10px;
    padding-bottom: 0; }
    @media (max-width: 767px) {
      html body div.footer-payment-methods ul, html body div.footer-payment-methods div.footer.content {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center; } }
    html body div.footer-payment-methods ul li, html body div.footer-payment-methods div.footer.content li {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: inline-block; }
      html body div.footer-payment-methods ul li a, html body div.footer-payment-methods div.footer.content li a {
        text-decoration: none;
        color: #fff;
        margin: 0;
        padding: 0; }
        html body div.footer-payment-methods ul li a:hover, html body div.footer-payment-methods div.footer.content li a:hover {
          text-decoration: none; }
        html body div.footer-payment-methods ul li a:after, html body div.footer-payment-methods div.footer.content li a:after {
          content: '|';
          padding-left: 10px;
          padding-right: 10px; }
      html body div.footer-payment-methods ul li:last-child a:after, html body div.footer-payment-methods div.footer.content li:last-child a:after {
        content: ''; }
  html body div.footer-payment-methods ul {
    padding-left: 20px; }

html body.mpblog-category-view main#maincontent div.columns,
html body.mpblog-month-view main#maincontent div.columns,
html body.mpblog-post-index main#maincontent div.columns {
  display: flex; }
  html body.mpblog-category-view main#maincontent div.columns div.column.main,
  html body.mpblog-month-view main#maincontent div.columns div.column.main,
  html body.mpblog-post-index main#maincontent div.columns div.column.main {
    float: none;
    width: 100%;
    order: 2; }
  html body.mpblog-category-view main#maincontent div.columns div.sidebar.sidebar-main,
  html body.mpblog-month-view main#maincontent div.columns div.sidebar.sidebar-main,
  html body.mpblog-post-index main#maincontent div.columns div.sidebar.sidebar-main {
    float: none;
    width: 100%;
    order: 1; }
    html body.mpblog-category-view main#maincontent div.columns div.sidebar.sidebar-main .mpblog-search,
    html body.mpblog-month-view main#maincontent div.columns div.sidebar.sidebar-main .mpblog-search,
    html body.mpblog-post-index main#maincontent div.columns div.sidebar.sidebar-main .mpblog-search {
      float: right !important;
      max-width: 200px; }
    html body.mpblog-category-view main#maincontent div.columns div.sidebar.sidebar-main .mp-sidebar.monthly,
    html body.mpblog-month-view main#maincontent div.columns div.sidebar.sidebar-main .mp-sidebar.monthly,
    html body.mpblog-post-index main#maincontent div.columns div.sidebar.sidebar-main .mp-sidebar.monthly {
      float: left;
      width: auto; }
      html body.mpblog-category-view main#maincontent div.columns div.sidebar.sidebar-main .mp-sidebar.monthly div.row div.block-title,
      html body.mpblog-category-view main#maincontent div.columns div.sidebar.sidebar-main .mp-sidebar.monthly div.row div.block-content,
      html body.mpblog-month-view main#maincontent div.columns div.sidebar.sidebar-main .mp-sidebar.monthly div.row div.block-title,
      html body.mpblog-month-view main#maincontent div.columns div.sidebar.sidebar-main .mp-sidebar.monthly div.row div.block-content,
      html body.mpblog-post-index main#maincontent div.columns div.sidebar.sidebar-main .mp-sidebar.monthly div.row div.block-title,
      html body.mpblog-post-index main#maincontent div.columns div.sidebar.sidebar-main .mp-sidebar.monthly div.row div.block-content {
        display: inline-block; }
    html body.mpblog-category-view main#maincontent div.columns div.sidebar.sidebar-main .mp-sidebar.category,
    html body.mpblog-month-view main#maincontent div.columns div.sidebar.sidebar-main .mp-sidebar.category,
    html body.mpblog-post-index main#maincontent div.columns div.sidebar.sidebar-main .mp-sidebar.category {
      float: left;
      width: 100%; }
      html body.mpblog-category-view main#maincontent div.columns div.sidebar.sidebar-main .mp-sidebar.category ul,
      html body.mpblog-month-view main#maincontent div.columns div.sidebar.sidebar-main .mp-sidebar.category ul,
      html body.mpblog-post-index main#maincontent div.columns div.sidebar.sidebar-main .mp-sidebar.category ul {
        margin: 0;
        padding: 0; }
        html body.mpblog-category-view main#maincontent div.columns div.sidebar.sidebar-main .mp-sidebar.category ul li,
        html body.mpblog-month-view main#maincontent div.columns div.sidebar.sidebar-main .mp-sidebar.category ul li,
        html body.mpblog-post-index main#maincontent div.columns div.sidebar.sidebar-main .mp-sidebar.category ul li {
          list-style-type: none;
          display: inline-block; }
          html body.mpblog-category-view main#maincontent div.columns div.sidebar.sidebar-main .mp-sidebar.category ul li a i,
          html body.mpblog-month-view main#maincontent div.columns div.sidebar.sidebar-main .mp-sidebar.category ul li a i,
          html body.mpblog-post-index main#maincontent div.columns div.sidebar.sidebar-main .mp-sidebar.category ul li a i {
            display: none; }
  html body.mpblog-category-view main#maincontent div.columns div.pager,
  html body.mpblog-month-view main#maincontent div.columns div.pager,
  html body.mpblog-post-index main#maincontent div.columns div.pager {
    float: left;
    width: 100%; }

html body.mpblog-category-view #mpblog-list-container div.post-list-body div.post-list-item,
html body.mpblog-month-view #mpblog-list-container div.post-list-body div.post-list-item,
html body.mpblog-post-index #mpblog-list-container div.post-list-body div.post-list-item {
  margin-bottom: 40px;
  height: 510px; }
  html body.mpblog-category-view #mpblog-list-container div.post-list-body div.post-list-item .post-item-wraper,
  html body.mpblog-month-view #mpblog-list-container div.post-list-body div.post-list-item .post-item-wraper,
  html body.mpblog-post-index #mpblog-list-container div.post-list-body div.post-list-item .post-item-wraper {
    height: 100%;
    border: 2px solid #e0e0e0; }
    html body.mpblog-category-view #mpblog-list-container div.post-list-body div.post-list-item .post-item-wraper:hover,
    html body.mpblog-month-view #mpblog-list-container div.post-list-body div.post-list-item .post-item-wraper:hover,
    html body.mpblog-post-index #mpblog-list-container div.post-list-body div.post-list-item .post-item-wraper:hover {
      box-shadow: none;
      border-color: #b1b1b1; }
    html body.mpblog-category-view #mpblog-list-container div.post-list-body div.post-list-item .post-item-wraper a.mp-read-more:hover,
    html body.mpblog-month-view #mpblog-list-container div.post-list-body div.post-list-item .post-item-wraper a.mp-read-more:hover,
    html body.mpblog-post-index #mpblog-list-container div.post-list-body div.post-list-item .post-item-wraper a.mp-read-more:hover {
      font-weight: 700;
      text-decoration: underline; }

.post-info-wraper h2.mp-post-title a {
  font-size: 16px;
  line-height: 20px;
  display: block; }

html.nav-open .nav-toggle:before {
  content: '\e616';
  color: #000000; }

html .nav-toggle:before {
  color: #000000; }

html .nav-toggle {
  top: 10px; }

@media (max-width: 767px) {
  html body header.page-header a.logo,
  html body header.page-header strong.logo {
    position: relative;
    left: 49%;
    transform: translateX(-51%);
    margin: 0 0 10px; } }

html body .nav-sections {
  margin-bottom: 0; }

html body .page-wrapper div.breadcrumbs {
  background: #151524;
  width: 100%;
  max-width: none;
  padding: 0; }
  html body .page-wrapper div.breadcrumbs ul.items {
    margin-left: auto;
    margin-right: auto;
    max-width: 1220px;
    padding: 10px 0; }
    html body .page-wrapper div.breadcrumbs ul.items li {
      color: #fff; }
      html body .page-wrapper div.breadcrumbs ul.items li a {
        color: #fff;
        font-weight: 300; }
    @media (max-width: 1295px) {
      html body .page-wrapper div.breadcrumbs ul.items {
        width: calc(100% - 60px);
        max-width: 100%; } }

@media only screen and (max-width: 767px) {
  html body div.page-wrapper div.sections.nav-sections {
    top: 61px;
    box-shadow: none;
    border-top: 1px solid #9a9a9a;
    width: 100%;
    z-index: 500000000; }
  html body div.page-wrapper .nav-sections-item-title {
    width: 50%; }
  html.nav-open .page-wrapper {
    left: 0; }
  html .nav-sections {
    background: #ffffff;
    left: calc(-1 * (100% - 0px)); }
    html .nav-sections div.section-items.nav-sections-items div.section-item-title.nav-sections-item-title {
      background: #ebebeb;
      border: 0;
      cursor: pointer; }
      html .nav-sections div.section-items.nav-sections-items div.section-item-title.nav-sections-item-title a {
        color: #565656;
        font-size: 14px; }
      html .nav-sections div.section-items.nav-sections-items div.section-item-title.nav-sections-item-title.active {
        background: #fff;
        border: 0; }
        html .nav-sections div.section-items.nav-sections-items div.section-item-title.nav-sections-item-title.active a {
          color: #000;
          font-weight: 700; }
    html .nav-sections div.section-item-content.nav-sections-item-content nav.navigation {
      background: transparent; }
      html .nav-sections div.section-item-content.nav-sections-item-content nav.navigation .parent .level-top:after {
        content: '\e616';
        transform: rotate(45deg);
        font-size: 10px;
        line-height: 24px;
        right: 15px; }
      html .nav-sections div.section-item-content.nav-sections-item-content nav.navigation .parent .level-top.ui-state-active:after {
        content: '-';
        transform: none;
        font-size: 30px;
        line-height: 12px; }
      html .nav-sections div.section-item-content.nav-sections-item-content nav.navigation li.level0 ul.level0 {
        background: #ebebeb; }
        html .nav-sections div.section-item-content.nav-sections-item-content nav.navigation li.level0 ul.level0 li a.ui-state-focus {
          font-weight: 300; }
        html .nav-sections div.section-item-content.nav-sections-item-content nav.navigation li.level0 ul.level0 li.all-category a {
          padding-left: 20px; }
        html .nav-sections div.section-item-content.nav-sections-item-content nav.navigation li.level0 ul.level0 li a {
          padding-left: 25px; }
        html .nav-sections div.section-item-content.nav-sections-item-content nav.navigation li.level0 ul.level0 li.level1 ul li {
          border-bottom: 1px solid #ccc;
          margin-left: 20px;
          margin-right: 20px; }
          html .nav-sections div.section-item-content.nav-sections-item-content nav.navigation li.level0 ul.level0 li.level1 ul li:last-child {
            border: 0; }
          html .nav-sections div.section-item-content.nav-sections-item-content nav.navigation li.level0 ul.level0 li.level1 ul li a {
            font-size: 12px;
            color: #5f5f5f;
            padding-left: 5px; }
      html .nav-sections div.section-item-content.nav-sections-item-content nav.navigation li.level0.active {
        border-left: 5px solid #151525; }
      html .nav-sections div.section-item-content.nav-sections-item-content nav.navigation a {
        background: transparent;
        color: #000;
        font-size: 14px;
        border-color: transparent; }
        html .nav-sections div.section-item-content.nav-sections-item-content nav.navigation a.ui-state-active, html .nav-sections div.section-item-content.nav-sections-item-content nav.navigation a.ui-state-focus {
          font-weight: 700;
          border-left: 0; }
      html .nav-sections div.section-item-content.nav-sections-item-content nav.navigation .level0 > .level-top {
        text-transform: none; }
      html .nav-sections div.section-item-content.nav-sections-item-content nav.navigation .level0:first-child {
        border-top: 0; }
      html .nav-sections div.section-item-content.nav-sections-item-content nav.navigation .level0:last-child {
        border-bottom: 0; } }

@media (min-width: 768px) {
  .page-products .products-grid .product-item:nth-child(4n + 1) {
    margin-right: 0;
    margin-left: 0; } }

html body .block.related .block-content.content {
  background: #ebebeb;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin: 0 -50vw;
  padding: 30px 0; }
  html body .block.related .block-content.content div.block-actions,
  html body .block.related .block-content.content div.products.wrapper.grid {
    display: block;
    position: relative;
    max-width: 1280px;
    margin: 0 auto; }

html body.catalog-product-view div.page-wrapper {
  overflow: hidden; }

html body div.column.main div.block.related div.block-title.title strong {
  display: block;
  clear: both;
  padding-top: 50px; }

@media only screen and (max-width: 767px) {
  .navigation .level0 > .level-top,
  .navigation .level0 > .level1 {
    font-weight: 300; } }

@media only screen and (max-width: 767px) {
  .navigation .level0.active > a:not(.ui-state-active),
  .navigation .level0.has-active > a:not(.ui-state-active),
  .navigation .submenu:not(:first-child) .active > a {
    border-color: #eb3e32;
    border-style: solid;
    border-width: 0 0 0 8px;
    color: #333333;
    font-weight: 700; } }

body.catalog-product-view h1.page-title {
  color: #000000; }

body.catalog-product-view div.column.main {
  margin-top: 30px; }

body.catalog-product-view div.product-info-price div.price-box.price-final_price,
body.catalog-product-view div.product-info-price div.product-info-stock-sku {
  display: block;
  position: relative;
  text-align: left;
  width: 100%;
  overflow: hidden;
  padding-left: 0; }

body.catalog-product-view div.product-info-price .price-box.price-final_price span.special-price:after {
  content: 'OFFER';
  width: 110px;
  height: 40px;
  text-align: center;
  display: block;
  background: #eb3e32;
  color: #fff;
  padding: 13px 0 13px 14px;
  top: -30px;
  right: -50px;
  position: absolute;
  transform: rotate(45deg);
  line-height: 82px;
  font-weight: 700;
  z-index: 1;
  left: auto; }

body.catalog-product-view div.product-info-price .available div {
  color: #51c47d; }

body.catalog-product-view div.product-info-price .price-box .price-container .price,
body.catalog-product-view div.product-info-price .price-box .price-container .price-label {
  color: #000000; }

body.catalog-product-view .box-tocart .action.tocart,
body.catalog-product-view button[type="submit"] {
  background: #363f4d;
  border: 0;
  border-radius: 0;
  max-width: 200px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  width: auto;
  height: 45px;
  line-height: 1;
  padding: 0 40px;
  font-size: 14px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  transition: all 0.3s ease-in-out; }
  body.catalog-product-view .box-tocart .action.tocart:hover,
  body.catalog-product-view button[type="submit"]:hover {
    background: #1e2d50;
    font-weight: 300; }

div.footer.content ul.footer.links:first-child {
  display: none; }
